import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "../../styles/global.css"
import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/anton"
import "@fontsource/montserrat"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/900.css"
import "@fontsource/museomoderno"

export default function Layout({ children }) {
  return (
    <div className="layout">
      <Navbar />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}
