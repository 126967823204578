import React, { useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`

const MenuIcon = styled.button`
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 220;

  div {
    width: 1.5rem;
    height: 0.2rem;
    background: #faa62d;
    border-radius: 5px;
    transform-origin: 1px;
    position: relative;
    z-index: 220;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }
    :nth-child(3) {
      transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const MenuLinks = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 50%;
  z-index: 199;
  background: var(--cool-black);
  border-left: 3px solid var(--warm-orange);
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 300ms;
  transform: ${({ nav }) => (nav ? "translateX(0%)" : "translateX(100%)")};

  ul {
    list-style-type: none;
  }

  li {
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
    color: var(--white);
    font-size: 1.5rem;

    :hover {
      color: #faa62d;
      transition: color 200ms;
    }
  }
`

export default function BurgerMenu() {
  const [nav, showNav] = useState(false)

  return (
    <Wrapper>
      <MenuIcon nav={nav} onClick={() => showNav(!nav)}>
        <div />
        <div />
        <div />
      </MenuIcon>
      <MenuLinks nav={nav}>
        <ul>
          <li>
            <a href="/what-we-do">What we do</a>
          </li>
          <li>
            <a href="/about">About us</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </MenuLinks>
    </Wrapper>
  )
}
