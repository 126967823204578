import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FaLinkedinIn } from "react-icons/fa"

const Footer = styled.footer`
  width: 100%;
  background: var(--cool-black);
  bottom: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  p {
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
`
const FooterContainer = styled.div`
  width: 70%;
  height: 300px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  margin: 50px 0;

  @media (max-width: 1023px) {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    width: 80%;
  }
`

const FooterH2 = styled.h2`
  font-family: "Open Sans", sans-serif;
  color: var(--white);
  font-size: 20px;
  text-align: right;

  @media (max-width: 1023px) {
    text-align: center;
    margin-top: 20px;
  }
`

const FootItem0 = styled.div`
  display: flex;
  flex-flow: column wrap;

  @media (max-width: 1023px) {
    align-items: center;
    width: 100%;
    margin-bottom: 1em;
  }

  h1 {
    font-family: "MuseoModerno", cursive;
    color: var(--white);
    text-align: left;
    font-weight: 400;
  }
  p {
    font-family: "Open Sans", sans-serif;
    color: var(--white);
    text-align: left;
    margin: 0;
  }
  h1,
  p {
    @media (max-width: 1023px) {
      text-align: center;
    }
  }
  .social-icons {
    margin-top: 115px;

    @media (max-width: 1023px) {
      display: none;
    }
    svg {
      fill: #0072b1;
      transition: 0.3s;
    }
    svg:hover {
      fill: #faa62d;
    }
  }
`
const FootItem1 = styled.div`
  display: flex;
  flex-flow: column wrap;

  @media (max-width: 1023px) {
    width: 100%;
  }
  div,
  a {
    text-align: right;
    color: white;
    line-height: 1;
    margin: 10px 0;
    color: white;

    @media (max-width: 1023px) {
      text-align: center;
      line-height: 2;
      margin: 10px;
    }
  }
  a:hover {
    text-decoration: underline;
  }

  div :nth-child(2) {
    margin-top: 18px;
    padding-top: 10px;
  }
`

const FootItem2 = styled.div`
  p {
    color: white;
    text-align: right;
    padding-top: 10px;

    @media (max-width: 1023px) {
      text-align: center;
      padding: 0;
    }
  }
`
const FootItem3 = styled.div`
  p,
  a {
    color: white;
    text-align: right;
    padding-top: 10px;
    text-decoration: underline;

    @media (max-width: 1023px) {
      text-align: center;
      padding: 0;
      line-height: 3;
      margin: 0;
    }
  }
`
const FootItem4 = styled.div`
  flex-basis: 90%;
  text-align: center;
  padding-top: 2em;

  @media (min-width: 1024px) {
    display: none;
  }
  svg {
    fill: #0072b1;
    transition: 0.3s;
  }
  svg:hover {
    fill: #faa62d;
  }
`

export default function Navbar() {
  return (
    <Footer>
      <FooterContainer>
        <FootItem0>
          <div className="logo-catchline">
            <h1>ripplz</h1>
            <p>Developing The Future!</p>
          </div>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/company/ripplz/"
              target="_blank"
              rel="noreferrer"
              aria-label="linkedin button"
            >
              <FaLinkedinIn size={25} />
            </a>
          </div>
        </FootItem0>
        <FootItem1>
          <FooterH2>LINKS</FooterH2>
          <div>
            <Link to="/about">About Us</Link>
          </div>
          <div>
            <Link to="/what-we-do">What We Do</Link>
          </div>
          <div>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
          <div>
            <Link to="/terms">Terms &amp; Conditions</Link>
          </div>
        </FootItem1>
        <FootItem2>
          <FooterH2>FIND US</FooterH2>
          <p>
            Airedale House,
            <br />
            423 Kirkstall Road,
            <br />
            Leeds, LS4 2EW
          </p>
        </FootItem2>
        <FootItem3>
          <FooterH2>CONTACT US</FooterH2>
          <p>
            <a href="mailto:info@ripplz.co.uk">info@ripplz.co.uk</a>
          </p>
          <p>
            <a href="tel:+44113-3572076">+44 (0)113 3572076</a>
          </p>
        </FootItem3>
        <FootItem4>
          <a
            href="https://www.linkedin.com/company/ripplz/"
            target="_blank"
            rel="noreferrer"
            aria-label="linkedin button"
          >
            <FaLinkedinIn size={25} />
          </a>
        </FootItem4>
      </FooterContainer>
      <div className="copyright">
        <p>Copyright {new Date().getFullYear()} - Ripplz</p>
      </div>
    </Footer>
  )
}
