import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "../../images/assets/logo.svg"
import styled from "styled-components"
import BurgerMenu from "./BurgerMenu"

const Nav = styled.nav`
  color: ${({ active }) => (active ? "#FAA62D" : "#fff")};
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  margin-top: 10px;
  top: 0;
  z-index: 998;
  padding: 0 10%;
`

const NavLogo = styled(Link)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    width: 100px;
    height: 100px;
    fill: #faa62d;
  }
`
const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
`
const NavItem = styled.li`
  display: flex;

  @media (max-width: 1023px) {
    display: none;
  }
`
const NavLinks = styled(Link)`
  color: ${({ active }) => (active ? "#FAA62D" : "#fff")};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover::before {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
  }
  &:before {
    margin-right: 10px;
    content: "(";
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
  }
  &::before {
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
  }

  &:hover::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
  }
  &::after {
    margin-left: 10px;
    content: ")";
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  &::after {
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    display: table;

    &:hover {
      color: #110cde;
      transition: all 0.3s ease;
    }
  }
`

export default function Navbar() {
  const [scroll, setScroll] = useState(false)

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  useEffect(() => {
    changeNav()
    window.addEventListener("scroll", changeNav)
  }, [])

  return (
    <>
      <div>
        <Nav>
          <NavLogo to="/">
            <Logo />
          </NavLogo>
          <NavMenu active={scroll}>
            <NavItem>
              <NavLinks to="/what-we-do" active={scroll}>
                WHAT WE DO
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/about" active={scroll}>
                ABOUT US
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/contact" active={scroll}>
                GET IN TOUCH
              </NavLinks>
            </NavItem>
          </NavMenu>
          <BurgerMenu />
        </Nav>
      </div>
    </>
  )
}
